import React, { useEffect, useMemo, useState } from "react"
import "./style.scss"

import { ATTACHMENT_TYPES_OBJECT } from "utility/constants"
import { AttachmentType, AttachmentTypeInfo } from "types/attachment"
import { PlusOutlined } from "@ant-design/icons"
import ImageUploader from "components/uploader/Image"
import FileUploader from "components/uploader/File"
import { UploadFile } from "antd/es/upload/interface"
import InputWithLabel from "components/InputWithLabel"
import KamaModal from "../Component"
import { nonNullable } from "utility"
import { AllPageType } from "types/allPage"

export type AddAttachmentModalProps = {
  open: boolean
  title: string
  allowTypes?: string[]
  maxNumber?: number
  type?: AllPageType
  onClose: () => void
  confirm: (e: React.MouseEvent, file: UploadFile[]) => void
}

export default function AddAttachmentModal(props: AddAttachmentModalProps) {
  const { open, title, allowTypes, maxNumber, type: buttonType, onClose, confirm } = props
  const [type, setType] = useState<AttachmentType>()
  const [fileList, setFileList] = useState<UploadFile[]>()
  const [disabled, setDisabled] = useState<boolean>(true)

  const onChangeFile = (fileList: UploadFile[]) => {
    return setFileList(fileList)
  }

  const { types } = useMemo(() => {
    const keys = Object.keys(ATTACHMENT_TYPES_OBJECT) as AttachmentType[]
    const types = keys
      .map((key) => {
        const info = ATTACHMENT_TYPES_OBJECT[key] as AttachmentTypeInfo
        if (allowTypes && !allowTypes.includes(key)) return undefined
        return { key: key, ...info }
      })
      .filter(nonNullable)
    if (types.length === 1) {
      setType(types[0].key)
    }
    return {
      types: types,
    }
  }, [open])

  useEffect(() => {
    let disabled = false
    if (!fileList?.length) {
      disabled = true
    }
    setDisabled(disabled)
  }, [fileList])

  return (
    <KamaModal
      visible={open}
      type={buttonType}
      headerProps={{ title, onClose: onClose }}
      footerProps={{
        onClose: onClose,
        onConfirm: (e) => {
          if (!fileList?.length || !type) return
          return confirm(e, fileList)
        },
        disabledConfirm: disabled,
      }}
    >
      <div className="addAttachmentModal">
        {type ? (
          <div className="addAttachmentModalTypeDiv">
            <InputWithLabel
              label={type === "image" ? "IMAGE" : "FILE"}
              Input={
                <div className="addAttachmentModalTypeUploaderDiv">
                  {type === "image" ? (
                    <ImageUploader
                      maxLength={maxNumber || 1}
                      uploadName={"SELECT_SINGLE_IMAGE"}
                      fileList={fileList}
                      type={buttonType}
                      uploadImageValues={(fileList: UploadFile[]) => {
                        onChangeFile(fileList)
                      }}
                    />
                  ) : (
                    <FileUploader
                      maxLength={maxNumber || 1}
                      uploadName={ATTACHMENT_TYPES_OBJECT[type]?.uploadName || "SELECT_SINGLE_FILE"}
                      fileList={fileList}
                      type={buttonType}
                      acceptType={ATTACHMENT_TYPES_OBJECT[type]?.extension || undefined}
                      uploadFileValues={(fileList: UploadFile[]) => {
                        onChangeFile(fileList)
                      }}
                    />
                  )}
                </div>
              }
            />
          </div>
        ) : (
          <div className={"typeUploadSelectDiv"}>
            {types?.map((type) => {
              return (
                <div
                  key={type.key}
                  className="attachmentTypeSelectorDiv"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    return setType(type.key)
                  }}
                >
                  <div className="attachmentTypeInfoDiv" style={{ background: type.color }}>
                    <span>{type.name}</span>
                  </div>
                  <div className="attachmentTypePlusDiv">
                    <PlusOutlined />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </KamaModal>
  )
}
