import esLocale from "antd/es/locale/es_ES"
import enLocale from "antd/es/locale/en_GB"
import { Language } from "types/generic"
import dayjs from "dayjs"

import "dayjs/locale/en-gb"
import "dayjs/locale/es"

export const localeSelection = (language: Language) => {
  let locale = enLocale
  switch (language) {
    case "es":
      locale = esLocale
      break
  }
  return locale
}

export const dayjsSelection = (language: string) => {
  switch (language) {
    case "es":
      dayjs.locale("es")
      break
    default:
      dayjs.locale("en-gb")
      break
  }
}
