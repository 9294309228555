import React, { useEffect, useState } from "react"
import "./style.scss"
import { AllPageColors, AllPageType } from "types/allPage"
import { useTranslation } from "react-i18next"
import { getColorsByType } from "../utility"

type AllPageFooterProps = {
  type?: AllPageType
}

export default function AllPageFooter(props: AllPageFooterProps) {
  const { type } = props
  const { t } = useTranslation()
  const [logoTypes, setLogoTypes] = useState<string[]>([])
  const [logoImages, setLogoImages] = useState<{ [key: string]: string[] }>({})
  const [colors, setColors] = useState<AllPageColors>()

  useEffect(() => {
    let logoTypes = ["partners"]
    let logoImages: { [key: string]: string[] } = { partners: [require("assets/images/allPage/kama.png")] }
    switch (type) {
      case "kings-league-america":
        logoTypes = ["title_partners", "main_partners", "official_partners"]
        logoImages = {
          title_partners: [require("assets/images/allPage/footer/santander-black.png")],
          main_partners: [
            require("assets/images/allPage/footer/bimbo-black.png"),
            require("assets/images/allPage/footer/sportium-black.png"),
          ],
          official_partners: [
            require("assets/images/allPage/footer/mc-black.png"),
            require("assets/images/allPage/footer/adidas-black.png"),
          ],
        }
        break
      case "queens-league-america":
        logoTypes = ["title_partners", "main_partners", "official_partners"]
        break
      default:
        break
    }
    setLogoTypes(logoTypes)
    setLogoImages(logoImages)
    setColors(getColorsByType(type || "kama"))
  }, [type])

  return (
    <div className="allPageFooter">
      <div className="allPageFooterDiv">
        <div className="allPageSponsorsDiv">
          {logoTypes?.map((logoType, index) => {
            return (
              <div key={index + 1} className={"allPageFooterGroupsDiv"}>
                <div
                  className="allPageFooterGroupsTitleDiv"
                  style={{ borderColor: colors?.footer, color: colors?.footer }}
                >
                  <h5>{t(logoType + "_TITLE")}</h5>
                </div>
                <div className="allPageFooterGroupsImagesDiv">
                  {logoImages[logoType]?.map((image, index) => {
                    return (
                      <div
                        key={index + 1}
                        className={"allPageFooterImageDiv" + (index > 0 ? " allPageFooterImageDivPadding" : "")}
                      >
                        <img key={index + 1} className="allPageFooterImage" src={image} alt={"allPageFooterImage"} />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
