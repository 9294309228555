import React from "react"
import { useTranslation } from "react-i18next"
import { InfoCircleFilled } from "@ant-design/icons"
import "./style.scss"
import { externalLinkNavigation } from "utility"

type InputWithLabelProps = {
  mandatory?: boolean
  vertical?: boolean
  small?: boolean
  label?: string
  labelColor?: string
  mandatoryLabelColor?: string
  Input: JSX.Element
  externalLink?: string
  bigLabel?: boolean
}

export default function InputWithLabel(props: InputWithLabelProps) {
  const { Input, label, mandatory, vertical, small, labelColor, mandatoryLabelColor, externalLink, bigLabel } = props
  const { t } = useTranslation()
  const goToExternalLink = (e: React.MouseEvent) => {
    if (externalLink) {
      e.preventDefault()
      externalLinkNavigation(externalLink)
    }
  }
  return (
    <div className="inputWithLabelGlobalDiv">
      <div
        className={
          "inputWithLabelDiv" +
          (vertical ? " inputWithLabelVerticalDiv" : "") +
          (small ? " inputWithLabelSmallDiv" : "") +
          (bigLabel ? " inputWithLabelBigDiv" : "")
        }
      >
        {label ? (
          <div className="inputWithLabelTitleDiv">
            <span style={{ color: labelColor || undefined }}>{t(label)}</span>
            {mandatory ? (
              <span className="inputWithLabelTitleMandatoryDiv" style={{ color: mandatoryLabelColor || undefined }}>
                *
              </span>
            ) : null}
            {externalLink ? (
              <InfoCircleFilled
                className="externalLinkIconDiv"
                onClick={goToExternalLink}
                style={{ color: mandatoryLabelColor || undefined }}
              />
            ) : null}
          </div>
        ) : null}
        <div className="inputWithLabelValueDiv">{Input}</div>
      </div>
    </div>
  )
}
