import React from "react"
import { useTranslation } from "react-i18next"

export type NavigationElementProps = {
  step: number
  title: string
  status: "disabled" | "available" | "active" | "complete"
}

export default function NavigationElement(
  props: NavigationElementProps & {
    onClick: (e: React.MouseEvent, stepNumber: number) => void
    totalSteps: number
  },
) {
  const { status, step, title, totalSteps, onClick } = props
  const { t } = useTranslation()
  return (
    <div className={"bs-wizard-step " + status} style={{ width: "calc(100% / " + totalSteps + ")" }}>
      {/* <div className="progress-default" /> */}
      <div className="progress-container">
        <div className="progress">
          <div className="progress-bar" />
        </div>
        <a onClick={status !== "disabled" ? (e) => onClick(e, step) : undefined} className="bs-wizard-dot">
          <span>{step}</span>
        </a>
      </div>
      <div className={"bs-wizard-info text-center bs-wizard-info-" + status}>
        <span className="step-title">{t(title)}</span>
      </div>
    </div>
  )
}
