import React from "react"
import { useTranslation } from "react-i18next"
import "./style.scss"
import { Checkbox, Input } from "antd"

type GenericInputProps = {
  allowClear?: boolean
  placeholder?: string
  value?: string
  suffix?: string
  small?: boolean
  disabled?: boolean
  readOnly?: boolean
  alertMessage?: string
  alertMessageColor?: string
  onChange?: (e: string | null) => void
}

export default function GenericInputKama(props: GenericInputProps) {
  const {
    allowClear,
    placeholder,
    value,
    suffix,
    small,
    disabled,
    readOnly,
    alertMessage,
    alertMessageColor,
    onChange,
  } = props
  const { t } = useTranslation()
  return (
    <div className="genericInputGlobalKamaDiv">
      <div className={"genericInputKamaDiv" + (small ? " genericInputKamaSmallDiv" : "")}>
        <Input
          size="large"
          allowClear={allowClear}
          className={"genericInputKamaElement" + (readOnly ? " genericInputKamaElementReadOnly" : "")}
          placeholder={placeholder ? t(placeholder) : undefined}
          suffix={suffix}
          onChange={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (onChange) {
              onChange(e.target.value)
            }
          }}
          value={value || undefined}
          disabled={disabled || undefined}
          readOnly={readOnly || undefined}
        />
        {alertMessage ? (
          <div className="genericInputKamaElementWarningDiv" style={{ color: alertMessageColor || undefined }}>
            <span>{t(alertMessage)}</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}
