import axios from "axios"
//import i18n from "languages/index"

const api = axios.create({
  withCredentials: true,
})

api.interceptors.request.use((config) => {
  /*config.headers = {
    "kama-language": i18n.language,
  }*/
  return config
})

export default api
