import React, { useEffect, useState } from "react"
import "./style.scss"
import { AllPageColors, AllPageType } from "types/allPage"
import { LANGUAGES } from "utility/constants"
import { Language } from "types/generic"
import SelectKama from "components/inputs/Select"
import AllPageFooter from "../Footer"
import { externalLinkNavigation } from "utility"
import { useTranslation } from "react-i18next"
import { getColorsByType } from "../utility"

type AllPageProps = {
  children?: JSX.Element
  type?: AllPageType
  language: Language
  onChangeLanguage: (language: Language) => void
}

export default function AllPage(props: AllPageProps) {
  const { children, type, language, onChangeLanguage } = props
  const { t } = useTranslation()

  const [backgroundImage, setBackgroundImage] = useState<string>()
  const [showOpacity, setShowOpacity] = useState<boolean>()
  const [showFooter, setShowFooter] = useState<boolean>()
  const [topLeftImage, setTopLeftImage] = useState<string>()
  const [fontContainer, setFontContainer] = useState<string>()
  const [poweredBy, setPoweredBy] = useState<string>()
  const [colors, setColors] = useState<AllPageColors>()
  const [additionalStringHeader, setAdditionalStringHeader] = useState<string>()

  useEffect(() => {
    let topLeftImage = require("assets/images/allPage/kama.png")
    let backgroundImage = require("assets/images/allPage/background_kama.jpg")
    let showOpacity = true
    let showFooter = true
    let fontContainer: string | undefined = undefined
    let poweredBy: string | undefined = undefined
    let additionalStringHeader: string | undefined = undefined
    switch (type) {
      case "kings-league-america":
        topLeftImage = require("assets/images/allPage/logo-kl-am-center.png")
        backgroundImage = require("assets/images/allPage/kl-back.png")
        //topLeftImage = require("assets/images/allPage/logo-kwc-am-left.png")
        //backgroundImage = require("assets/images/allPage/kwc-back.png")
        showOpacity = false
        showFooter = false
        fontContainer = "kings-league-font"
        //poweredBy = require("assets/images/allPage/footer/kama_white.png")
        poweredBy = require("assets/images/allPage/kama_white.png")
        //additionalStringHeader = "España | Barcellona"
        break
      case "queens-league-america":
        //topLeftImage = require("assets/images/allPage/logo-kl-am-left.png")
        //backgroundImage = require("assets/images/allPage/kl-back.png")
        topLeftImage = require("assets/images/allPage/logo-kwc-am-left.png")
        backgroundImage = require("assets/images/allPage/kwc-back.png")
        showOpacity = false
        showFooter = false
        fontContainer = "kings-league-font"
        poweredBy = require("assets/images/allPage/footer/kama_white.png")
        break
      default:
        break
    }
    setBackgroundImage(backgroundImage)
    setShowOpacity(showOpacity)
    setTopLeftImage(topLeftImage)
    setFontContainer(fontContainer)
    setPoweredBy(poweredBy)
    setShowFooter(showFooter)
    setColors(getColorsByType(type || "kama"))
    setAdditionalStringHeader(additionalStringHeader)
  }, [type])

  return (
    <div className="allPage">
      <div
        className={"allPageDiv" + (fontContainer ? " " + fontContainer : "")}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {showOpacity ? <div className="allPageOpacityDiv" /> : null}
        <div className="header-container">
          <div className="allPageTopLeftDiv">
            <img src={topLeftImage} alt="topLeftImage" className="allPageTopLeft" />
            {additionalStringHeader ? (
              <div className="allPageAdditionalStringHeaderDiv" style={{ color: colors?.footer }}>
                {additionalStringHeader}
              </div>
            ) : null}
          </div>
          <div className="allPageTopRightDiv">
            <div>
              <SelectKama options={LANGUAGES} value={language} onChange={(e) => onChangeLanguage(e as Language)} />
            </div>
            {poweredBy ? (
              <div
                className={"allPagePoweredBy"}
                onClick={(e) => {
                  e.preventDefault()
                  externalLinkNavigation("https://www.instagram.com/kama.sport.official/")
                }}
              >
                <div className="allPagePoweredTitleBy" style={{ color: colors?.footer }}>
                  <span>{t("powered_by_TITLE")}</span>
                </div>
                <div className="allPagePoweredImageDiv">
                  <img className="allPagePoweredImage" src={poweredBy} alt={"allPagePoweredImage"} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="body-container">
          <div className="allPageContentContainer">{children}</div>
        </div>
        {showFooter ? (
          <div className="footer-container">
            <AllPageFooter type={type} />
          </div>
        ) : null}
      </div>
    </div>
  )
}
