import React, { useState, MouseEventHandler } from "react"
import { useTranslation } from "react-i18next"

import { CloseOutlined } from "@ant-design/icons"

import "./style.scss"
import { AllPageButtonType, AllPageColors, AllPageType } from "types/allPage"
import { getButtonByType } from "components/AllPage/utility"
import Button from "components/Button"

type ErrorRegistrationCardProps = {
  type: AllPageType
  confirm: MouseEventHandler
}

export default function ErrorRegistrationCard(props: ErrorRegistrationCardProps) {
  const { type, confirm } = props
  const { t } = useTranslation()

  const [buttonType, setButtonType] = useState<AllPageButtonType>(getButtonByType(type))

  return (
    <div className="errorRegistrationCard">
      <div className="errorGlobalDiv">
        <div className="errorIconDiv">
          <CloseOutlined className="errorIcon" />
        </div>
        <div className="errorTitleDiv">
          <span>{t("ERROR")}</span>
        </div>
        <div className="errorTextDiv">
          <span>{t("ERROR_DESCRIPTION")}</span>
        </div>
        <div className="errorReloadButtonDiv">
          <Button
            type={buttonType}
            onClick={confirm}
            content={
              <div className="errorReloadButton">
                <span>{t("RETRY")}</span>
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}
function getColorsByType(type: string): AllPageColors | (() => AllPageColors) {
  throw new Error("Function not implemented.")
}
