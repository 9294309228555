import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./style.scss"
import { Input } from "antd"

type InputNumberProps = {
  allowClear?: boolean
  placeholder?: string
  suffix?: string
  value?: number
  small?: boolean
  disabled?: boolean
  readOnly?: boolean
  maxValue?: number
  big?: boolean
  allowDecimals?: boolean
  onChange?: (value?: number | null) => void
}

export default function InputNumberKama(props: InputNumberProps) {
  const { allowClear, placeholder, allowDecimals, onChange, value, suffix, small, disabled, readOnly, maxValue, big } =
    props
  const { t } = useTranslation()
  const [inputValue, setInputValue] = React.useState<string | undefined>(value?.toString() || undefined)

  const onChangeFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (onChange) {
      let value = e.target.value?.length ? e.target.value : undefined
      if (value === undefined) {
        setInputValue(undefined)
        onChange(undefined)
        return null
      }
      value = value.replace(",", ".")
      let regex = allowDecimals ? /^-?\d+(\.\d+)?([eE][-+]?\d+)?$/ : /^-?\d+$/
      if (!regex.test(value)) {
        if (allowDecimals && value[value.length - 1] === ".") {
          setInputValue(value)
        }
        return null
      }
      let response: number | null = null
      if (value === "0") {
        response = 0
      }
      if (value) {
        response = Number(value)
      }
      if (response && maxValue && response > maxValue) {
        return null
      }
      onChange(response)
      if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
        value = value.substring(1)
      }
      setInputValue(value)
    }
  }

  useEffect(() => {
    if (value || value === 0) {
      setInputValue(value.toString())
    }
  }, [value])

  return (
    <div className="inputNumberGlobalKamaDiv">
      <div
        className={
          "inputNumberKamaDiv" + (small ? " inputNumberKamaSmallDiv" : "") + (big ? " inputNumberKamaBigDiv" : "")
        }
      >
        <Input
          size="large"
          allowClear={allowClear}
          className={"inputNumberKamaElement" + (readOnly ? " inputNumberKamaElementReadOnly" : "")}
          placeholder={placeholder ? t(placeholder) : undefined}
          suffix={suffix}
          onChange={onChangeFunction}
          value={inputValue || ""}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}
