import React, { MouseEventHandler } from "react"
import "./style.scss"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { AllPageType } from "types/allPage"
import { getButtonByType } from "components/AllPage/utility"

type ModalButtonsProps = {
  onClose?: MouseEventHandler
  onConfirm?: MouseEventHandler
  onDelete?: MouseEventHandler
  type?: AllPageType
  disabledConfirm?: boolean | null
  onCloseLabel?: string
  onConfirmLabel?: string
  onDeleteLabel?: string
}

export default function ModalButtons(props: ModalButtonsProps) {
  const { disabledConfirm, onCloseLabel, onConfirmLabel, onDeleteLabel, type, onConfirm, onClose, onDelete } = props
  const { t } = useTranslation()
  return (
    <div className="modalFooterDiv">
      <div className="modalButtonsDiv">
        <div className="modalButtonsLeftDiv">
          {onDelete && !onDeleteLabel ? (
            <div className={"modalConfirmButtonDiv"}>
              <Button content={t("DELETE")} onClick={onDelete} type={"delete"} />
            </div>
          ) : null}
        </div>
        <div className="modalButtonsRightDiv">
          {onClose ? (
            <div
              className={
                "modalConfirmButtonDiv" + (onConfirm || (onDelete && onDeleteLabel) ? " modalCloseButtonDiv" : "")
              }
            >
              <Button content={t(onCloseLabel || "CLOSE")} onClick={onClose} type={"default"} />
            </div>
          ) : null}
          {onConfirm ? (
            <div className="modalConfirmButtonDiv">
              <Button
                content={t(onConfirmLabel || "CONFIRM")}
                disabled={disabledConfirm}
                onClick={onConfirm}
                type={onDelete && onDeleteLabel ? "inverse-primary" : getButtonByType(type || "kama")}
              />
            </div>
          ) : null}
          {onDelete && onDeleteLabel ? (
            <div className={"modalConfirmButtonDiv" + (onConfirm ? " modalCloseButtonDiv" : "")}>
              <Button content={t(onDeleteLabel || "CONFIRM")} onClick={onDelete} type={"delete"} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
