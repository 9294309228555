import React from "react"
import { useTranslation } from "react-i18next"

import { UploadFile } from "antd/es/upload/interface"
import { AllPageType } from "types/allPage"

import "./style.scss"
import Button from "components/Button"
import { getButtonByType } from "components/AllPage/utility"
import AddAttachmentModal from "components/modals/AddAttachments"
import KamaIcon from "components/Icon"

type AddAttachementProps = {
  title?: string
  type?: AllPageType
  value?: UploadFile[]
  disabled?: boolean
  allowTypes?: string[]
  maxNumber?: number
  onChange?: (value?: UploadFile[]) => void
}

export default function AddAttachmentKama(props: AddAttachementProps) {
  const { value, title, disabled, type, allowTypes, maxNumber, onChange } = props
  const { t } = useTranslation()

  const [modal, setModal] = React.useState<boolean>(false)

  return (
    <div className={"addAttachementGlobalKamaDiv"}>
      {modal && (
        <AddAttachmentModal
          open={true}
          title={title || "ADD_ATTACHMENT_MODAL_TITLE"}
          allowTypes={allowTypes}
          maxNumber={maxNumber}
          type={type}
          onClose={() => {
            setModal(false)
          }}
          confirm={(e, file) => {
            setModal(false)
            if (onChange) {
              if (file.length) {
                let newFiles: UploadFile[] = []
                if (value?.length) {
                  newFiles = value
                }
                file.forEach((element) => {
                  if (newFiles.length <= (maxNumber || 1)) newFiles.push(element)
                })
                return onChange(newFiles)
              }
              return undefined
            }
          }}
        />
      )}
      {value?.length
        ? value.map((element, index) => {
            return (
              <div
                key={index + 1}
                className="addAttachmentElementDiv"
                onClick={(e) => {
                  e.preventDefault()
                  value.splice(index, 1)
                  if (value.length === 0) {
                    return onChange && onChange(undefined)
                  }
                  onChange && onChange(value)
                }}
              >
                <div className="addAttachmentElementName">{element.name}</div>
                <div className="addAttachmentElementDelete">
                  <KamaIcon icon={"trash"} type={"warning"} />
                </div>
              </div>
            )
          })
        : null}
      {!value?.length || (maxNumber && value.length < maxNumber) ? (
        <div>
          <Button
            className="addAttachmentButtonContainerDiv"
            disabled={disabled}
            type={getButtonByType(type || "kama")}
            onClick={(e) => {
              setModal(true)
            }}
            content={
              <div className="addAttachmentButtonDiv">
                <KamaIcon icon="add" size="small" />
                <span>{t(title || "ADD_ATTACHMENT")}</span>
              </div>
            }
          />
        </div>
      ) : null}
    </div>
  )
}
