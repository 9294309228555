import React, { Component } from "react"
import { Upload } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { Translation } from "react-i18next"
import { getBase64, handleImageResize } from "utility/image"
import Button from "components/Button"
import "./style.scss"
import { getButtonByType } from "components/AllPage/utility"

export const uploadButton = (uploadName, disabled, type) => {
  return (
    <Translation>
      {(t) => (
        <div className="uploadButtonDiv">
          <Button
            type={getButtonByType(type)}
            disabled={disabled}
            content={
              <div className="uploadButtonContentDiv">
                <UploadOutlined className="uploadButtonIconDiv" />
                <div className={"centerTextButton"}>
                  <span>{t(uploadName)}</span>
                </div>
              </div>
            }
          />
        </div>
      )}
    </Translation>
  )
}

class ImageUploader extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onRemove = this.onRemove.bind(this)
    this.beforeUpload = this.beforeUpload.bind(this)
  }

  onChange = async ({ fileList }) => {
    const { uploadImageValues, maxLength, height, width } = this.props
    const newFileList = await handleImageResize(fileList, height, width)
    let preview = null
    if (maxLength === 1 && newFileList && newFileList[0]) {
      if (!newFileList[0].url && !newFileList[0].preview) {
        newFileList[0].preview = await getBase64(newFileList[0].originFileObj)
      }
      preview = newFileList[0].url || newFileList[0].preview
    }
    if (newFileList.length > maxLength) {
      newFileList.splice(maxLength)
    }
    return uploadImageValues(newFileList, preview)
  }

  onRemove = (file) => {
    const { uploadImageValues, fileList } = this.props
    const index = fileList.indexOf(file)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)
    return uploadImageValues(newFileList, null)
  }

  beforeUpload = (file) => {
    const { uploadImageValues, fileList } = this.props
    uploadImageValues([...fileList, file])
    return false
  }

  render() {
    const { fileList, maxLength, uploadName, disabled, listType, forceShowButton, type } = this.props
    return (
      <div className={"upload-container" + (forceShowButton ? " force-show-button" : "")}>
        <Upload
          accept={".png,.jpeg,.jpg,.svg,.webp"}
          beforeUpload={this.beforeUpload}
          onRemove={this.onRemove}
          onChange={this.onChange}
          multiple={maxLength > 1}
          fileList={fileList}
          listType={listType}
        >
          {!fileList || fileList.length < maxLength || forceShowButton
            ? uploadButton(uploadName, disabled, type)
            : null}
        </Upload>
      </div>
    )
  }
}

export default ImageUploader
