import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import translationEN from "./locales/en/translation"
import translationES from "./locales/es/translation"
import translationPT from "./locales/pt/translation"
import translationDE from "./locales/de/translation"
import translationFR from "./locales/fr/translation"
import translationJP from "./locales/jp/translation"
import translationIT from "./locales/it/translation"
import translationTR from "./locales/tr/translation"

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  // pt: {
  //   translation: translationPT,
  // },
  // de: {
  //   translation: translationDE,
  // },
  // fr: {
  //   translation: translationFR,
  // },
  // jp: {
  //   translation: translationJP,
  // },
  it: {
    translation: translationIT,
  },
  // tr: {
  //   translation: translationTR,
  // },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n

export function changeLanguage(language) {
  i18n.changeLanguage(language)
}
