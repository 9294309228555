import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.scss"
import { Input } from "antd"
import SelectKama, { SelectOptionProps } from "components/inputs/Select"
import { CountryDTO, Language } from "types/generic"
import { nonNullable } from "utility"

type MobileInputProps = {
  allowClear?: boolean
  placeholder?: string
  prefixCode?: string
  value?: number
  small?: boolean
  disabled?: boolean
  readOnly?: boolean
  big?: boolean
  language?: Language
  options?: CountryDTO[]
  onChange?: (value?: number, prefix?: string) => void
}

export default function MobileInputKama(props: MobileInputProps) {
  const { allowClear, prefixCode, placeholder, value, small, disabled, readOnly, big, language, options, onChange } =
    props
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<string>()
  const [inputCode, setInputCode] = useState<string>()
  const [availableCountries, setAvailableCountries] = useState<(SelectOptionProps & { isoCode: string | null })[]>([])

  useEffect(() => {
    setInputValue(value ? value.toString() : undefined)
    setInputCode(prefixCode ? prefixCode : undefined)
    if (!inputCode && availableCountries.length > 0) {
      const defaultCountry = language && availableCountries.find((country) => country.isoCode === language)
      setInputCode((defaultCountry?.id as string | undefined) || (availableCountries[0].id as string | undefined))
    }
  }, [prefixCode, value, options])

  useEffect(() => {
    const availableCountries = options
      ?.map((country) => {
        if (!country.prefix) {
          return null
        }
        return {
          id: country.prefix,
          name: country.prefix,
          image: country.image
            ? {
                id: country.id,
                path: country.image,
              }
            : undefined,
          isoCode: country.isoCode,
        }
      })
      .filter(nonNullable)
    setAvailableCountries(availableCountries || [])
  }, [options])

  useEffect(() => {
    if (!inputCode && availableCountries.length > 0) {
      setInputCode(availableCountries[0].id as string)
    }
  }, [availableCountries])

  const onChangeFunction = (e?: React.ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault()
    e?.stopPropagation()
    if (onChange) {
      const value = e?.target.value.replace(/\D+/g, "") || ""
      if (!value) {
        setInputValue("")
        onChange(undefined, inputCode)
        return null
      }
      const regex = /^-?\d+$/
      if (!regex.test(value)) {
        return null
      }
      let response: number | undefined = undefined
      if (value === "0") {
        response = 0
      }
      if (value) {
        response = Number(value)
      }
      onChange(response, inputCode)
      setInputValue(value)
    }
  }

  return (
    <div className="inputNumberMobileGlobalKamaDiv">
      <div
        className={
          "inputNumberKamaDiv" + (small ? " inputNumberKamaSmallDiv" : "") + (big ? " inputNumberKamaBigDiv" : "")
        }
      >
        <div className={"phone-input"}>
          <div className={"code-input"}>
            <SelectKama
              showSearch={true}
              onChange={(value) => {
                setInputCode((value || undefined) as string | undefined)
                onChange && onChange(inputValue ? parseInt(inputValue) : undefined, value as string | undefined)
              }}
              value={inputCode}
              options={availableCountries}
            />
          </div>
          <Input
            size="large"
            allowClear={allowClear}
            className={"inputNumberKamaElement" + (readOnly ? " inputNumberKamaElementReadOnly" : "")}
            placeholder={placeholder ? t(placeholder) : undefined}
            onChange={onChangeFunction}
            value={inputValue}
            disabled={disabled}
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  )
}
