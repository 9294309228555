import React from "react"

import "./style.scss"

export type KamaIconType =
  | "play-video"
  | "close"
  | "warning"
  | "download"
  | "arrow-left"
  | "arrow-right"
  | "arrow-up"
  | "arrow-down"
  | "add"
  | "settings"
  | "timer"
  | "edit"
  | "videok-events"
  | "home-v1"
  | "home-v2"
  | "videok-targets"
  | "list"
  | "tag"
  | "players"
  | "ball"
  | "favourite"
  | "element-4"
  | "performance-0"
  | "performance-1"
  | "penalties-0"
  | "penalties-1"
  | "full-list-0"
  | "full-list-1"
  | "full-list-2"
  | "match"
  | "search"
  | "search-done-exercises"
  | "calendar"
  | "players-report"
  | "staff"
  | "exercise"
  | "index-analysis"
  | "training-analysis"
  | "training-analysis-v2"
  | "opponent-analysis-0"
  | "opponent-analysis-1"
  | "file-management"
  | "logout-1"
  | "cup"
  | "notification"
  | "security"
  | "user-square"
  | "camera"
  | "trash"
  | "match-2"
  | "match"
  | "pdf"
  | "info-circle"
  | "finance"
  | "view"
  | "training"
  | "red-card"
  | "substitutions"
  | "y-r-cards"
  | "yellow-card"
  | "coaching"
  | "penalty-kick"
  | "import"
  | "pdf"
  | "has-data"
  | "has-video"
  | "tag-2"
  | "broadcast"
  | "tactical"

type KamaIconProps = {
  icon: KamaIconType
  className?: string
  type?: "primary" | "default" | "white" | "warning" | "disabled"
  size?: "small" | "medium" | "large"
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export default function KamaIcon(props: KamaIconProps) {
  const { type, size, onClick, icon, className } = props
  return (
    <div className={"kamaIconGlobal"} onClick={(e) => (onClick ? onClick(e) : undefined)}>
      <div
        className={
          "kamaIconDiv" +
          (type === "primary" ? " kamaIconPrimaryDiv" : "") +
          (type === "default" ? " kamaIconDefaultDiv" : "") +
          (type === "disabled" ? " kamaIconDisabledDiv" : "") +
          (type === "white" ? " kamaIconWhiteDiv" : "") +
          (type === "warning" ? " kamaIconWarningDiv" : "") +
          (size === "small" ? " kamaIconSmallDiv" : "") +
          (className ? " " + className : "")
        }
      >
        <img src={require("assets/images/icons/" + icon + ".png")} className="kamaIconImage" />
      </div>
    </div>
  )
}
