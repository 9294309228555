import { useEffect, useState } from "react"

import { AllPageType } from "types/allPage"

export const getInfoFromHostname = (): AllPageType => {
  let type: AllPageType = "kama"
  const hostname = window.location.hostname?.split(".kama.sport")
  switch (hostname?.[0]) {
    case "kingsleague-america":
      type = "kings-league-america"
      break
    case "queensleague-america":
      type = "queens-league-america"
      break
    default: //-> activate this line for test
      type = "kings-league-america"
      break
  }
  return type
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

export const handleBooleanParameter = (value: number): "true" | "false" => {
  if (value === 1) {
    return "true"
  } else {
    return "false"
  }
}

export const handleStringParameter = (value: string): string => {
  return value.trim()
}

export const useCountdown = (targetDate: number) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [days, hours, minutes, seconds]
}
export const externalLinkNavigation = (url: string) => {
  window.open(url, "_blank")?.focus()
}

export const useResolution = () => {
  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  return [width]
}
