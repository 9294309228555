import React, { MouseEventHandler } from "react"

import { Modal } from "antd"
import ModalHeader from "./Header"
import ModalButtons from "./Buttons"

import "./style.scss"
import { AllPageType } from "types/allPage"

type KamaModalProps = {
  className?: string
  visible?: boolean
  centered?: boolean
  size?: string
  closable?: boolean
  zIndex?: number
  children?: JSX.Element | null
  headerProps?: { title?: string | JSX.Element; onClose?: MouseEventHandler }
  type?: AllPageType
  footerProps?: {
    onClose?: MouseEventHandler
    onConfirm?: MouseEventHandler
    onDelete?: MouseEventHandler
    disabledConfirm?: boolean
    onCloseLabel?: string
    onConfirmLabel?: string
    onDeleteLabel?: string
  }
}

export default function KamaModal(props: KamaModalProps) {
  const { className, visible, size, closable, centered, children, headerProps, footerProps, zIndex, type } = props
  return (
    <Modal
      className={"modalContainer" + ((size ? " " + size : "") + (className ? " " + className : ""))}
      centered={centered}
      closable={closable}
      open={visible}
      footer={null}
      zIndex={zIndex || (size?.includes("all-page") ? 1030 : 1010)}
      title={headerProps && <ModalHeader {...headerProps} />}
    >
      <div className={"bodyContainer" + (footerProps ? "" : " bodyContainerWithNoFooter")}>{children}</div>
      {footerProps && <ModalButtons {...footerProps} type={type} />}
    </Modal>
  )
}

KamaModal.defaultProps = {
  centered: true,
  closable: false,
  size: "medium",
}
