import React from "react"
import { useTranslation } from "react-i18next"

import { Select } from "antd"

import { ImageDTO } from "types/generic"

import "./style.scss"
import { SizeType } from "antd/lib/config-provider/SizeContext"

export type SelectOptionProps = {
  id: number | string
  name?: string
  image?: ImageDTO
  element?: JSX.Element
  disabled?: boolean
}

type SelectProps = {
  mode?: "multiple"
  loading?: boolean
  showSearch?: boolean
  allowClear?: boolean
  options?: SelectOptionProps[]
  disabled?: boolean
  placeholder?: string | null
  value?: number | number[] | string | string[] | null
  size?: SizeType
  onChange?: (value: number | number[] | string | string[]) => void
  onSearch?: (value: number | number[] | string | string[]) => void
}

export default function SelectKama(props: SelectProps) {
  const { mode, showSearch, allowClear, placeholder, loading, value, options, disabled, size, onChange, onSearch } =
    props
  const { t } = useTranslation()

  return (
    <div className={"selectGlobalKamaDiv"}>
      <div className={"selectKamaDiv"}>
        <Select
          mode={mode}
          showSearch={onSearch ? true : showSearch}
          allowClear={allowClear}
          size={size || "large"}
          className={"selectKamaElement"}
          placeholder={
            placeholder ? (
              <span className={"selectKamaPlaceholder" + (mode ? "" : " selectKamaSinglePlaceholder")}>
                {t(placeholder)}
              </span>
            ) : null
          }
          loading={loading}
          filterOption={(input, option) =>
            !onSearch ? (option?.textvalue ?? "").toLowerCase().includes(input.toLowerCase()) : true
          }
          onChange={(e) => {
            if (onChange) {
              onChange(e)
            }
          }}
          onSearch={(e) => {
            if (onSearch) {
              onSearch(e)
            }
          }}
          disabled={disabled}
          value={value}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          {options?.map((option) => {
            return (
              <Select.Option textvalue={option.name} value={option.id} key={option.id} disabled={option.disabled}>
                {option.element ? (
                  option.element
                ) : (
                  <div className={option.image ? "selectKamaOptionGlobalImageDiv" : ""}>
                    {option.image ? (
                      <div className={"selectKamaOptionImageDiv"}>
                        <img className={"selectKamaOptionImage"} src={option.image.path} alt={"..."} />
                      </div>
                    ) : null}
                    <span className={"selectKamaOption"}>{option.name ? t(option.name) : "---"}</span>
                  </div>
                )}
              </Select.Option>
            )
          })}
        </Select>
      </div>
    </div>
  )
}
