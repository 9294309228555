import React, { useEffect, useState } from "react"
import "./style.scss"
import { getColorsByType } from "components/AllPage/utility"
import { AllPageCardColor, AllPageType } from "types/allPage"

export type AllPageCardProps = {
  type: AllPageType
  children?: JSX.Element
}

export default function AllPageCard(props: AllPageCardProps) {
  const { type, children } = props

  const [color, setColor] = useState<AllPageCardColor>()

  useEffect(() => {
    const color = getColorsByType(type)
    setColor(color)
  }, [type])

  if (!color) return null

  return (
    <div className="allPageCard">
      <div className="allPageCardDiv" style={{ backgroundColor: color.background, borderColor: color.border }}>
        {children}
      </div>
    </div>
  )
}
