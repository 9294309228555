import { message } from "antd"
import Resizer from "react-image-file-resizer"
export const MAX_IMAGE_SIZE = 10 * 1000 * 1000 // 10 MB
export const MAX_PDF_SIZE = 10 * 1000 * 1000 // 10 MB

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const imageDimension = (preview, height, width) => {
  return new Promise(async (resolve, reject) => {
    let img = new Image()
    img.src = await getBase64(preview)
    img.onload = () => {
      const imageHeight = img.height
      const imageWidth = img.width
      if (imageHeight === height && imageWidth === width) {
        return resolve()
      }
      return reject()
    }
    img.onerror = (err) => {
      return reject()
    }
  })
}

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      "file",
    )
  })

export const handleImageResize = async (fileList, height, width) => {
  const newFileList = []
  for (let i = 0; i < fileList.length; i++) {
    let file = fileList[i]
    //if (height && width) {
    // try {
    //   await imageDimension(file.originFileObj, height, width)
    // } catch (e) {
    //   message.warning({
    //     content: <div className="warningDimensionUploadDiv">Image dimension NOT correct</div>,
    //     duration: 2,
    //   })
    //   return null
    // }
    //}
    // if (file.size && file.size > MAX_IMAGE_SIZE) {
    //   file.originFileObj = await resizeFile(file.originFileObj)
    // }
    if (file.originFileObj) {
      if (file.originFileObj.size < MAX_IMAGE_SIZE) {
        newFileList.push(file)
      } else {
        message.warning({ content: <div className="warningDimensionUploadDiv">Image too large</div>, duration: 2 })
      }
    }
  }
  return newFileList
}
