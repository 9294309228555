import { API_PATH } from "utility/constants"
import api from "./axiosConfig"
import { RegistrationInfo } from "types/registration"
import { handleBooleanParameter, handleStringParameter } from "utility"

export const DraftPlayerAPI = {
  create: async (info: RegistrationInfo, token: string, draftId?: number): Promise<{ message: "success" }> => {
    const formData = new FormData()
    draftId && formData.append("draftId", draftId.toString())
    let shortName = handleStringParameter(info.firstName || "") + " " + handleStringParameter(info.lastName || "")
    shortName && formData.append("shortName", handleStringParameter(shortName))
    info.firstName && formData.append("firstName", handleStringParameter(info.firstName))
    info.lastName && formData.append("lastName", handleStringParameter(info.lastName))
    info.birthDate && formData.append("birthDate", info.birthDate.toDate().toISOString())
    info.countryId && formData.append("countryId", info.countryId.toString())
    info.secondaryCountryId && formData.append("secondaryCountryId", info.secondaryCountryId.toString())
    info.foot && formData.append("foot", info.foot)
    info.gender && formData.append("gender", info.gender)
    info.height && formData.append("height", info.height.toString())
    info.weight && formData.append("weight", info.weight.toString())
    info.role && formData.append("role", info.role)
    info.email && formData.append("email", handleStringParameter(info.email))
    info.isConditionAccepted && formData.append("isConditionAccepted", handleBooleanParameter(info.isConditionAccepted))
    info.hasWorkingPermit && formData.append("hasWorkingPermit", handleBooleanParameter(info.hasWorkingPermit))
    info.hasResidencePermit && formData.append("hasResidencePermit", handleBooleanParameter(info.hasResidencePermit))
    info.isAvailableToPlay && formData.append("isAvailableToPlay", handleBooleanParameter(info.isAvailableToPlay))
    info.nationalIdNumber && formData.append("nationalIdNumber", handleStringParameter(info.nationalIdNumber))
    info.nationalIdExpirationDate &&
      formData.append("nationalIdExpirationDate", info.nationalIdExpirationDate.toDate().toISOString())
    info.curpId && formData.append("curpId", handleStringParameter(info.curpId))
    info.curpExpirationDate && formData.append("curpExpirationDate", info.curpExpirationDate.toDate().toISOString())
    info.passportId && formData.append("passportId", handleStringParameter(info.passportId))
    info.passportExpirationDate &&
      formData.append("passportExpirationDate", info.passportExpirationDate.toDate().toISOString())
    info.countryOfResidenceId && formData.append("countryOfResidenceId", info.countryOfResidenceId.toString())
    info.regionOfResidenceId && formData.append("regionOfResidenceId", info.regionOfResidenceId.toString())
    info.address && formData.append("address", handleStringParameter(info.address))
    info.city && formData.append("city", handleStringParameter(info.city))
    info.postalCode && formData.append("postalCode", handleStringParameter(info.postalCode))
    const telephone = (info.telephonePrefix || "") + " " + (info.telephone || "")
    telephone && formData.append("telephone", telephone)
    info.tshirtSize && formData.append("tshirtSize", info.tshirtSize)
    info.displayName && formData.append("displayName", handleStringParameter(info.displayName))
    info.hasSocialSecurityNumber &&
      formData.append("hasSocialSecurityNumber", handleBooleanParameter(info.hasSocialSecurityNumber))
    info.socialSecurityNumber &&
      formData.append("socialSecurityNumber", handleStringParameter(info.socialSecurityNumber))
    info.hasPlayedInYouthTeam &&
      formData.append("hasPlayedInYouthTeam", handleBooleanParameter(info.hasPlayedInYouthTeam))
    info.youthTeam && formData.append("youthTeam", handleStringParameter(info.youthTeam))
    info.yearsSpentInYouthTeams && formData.append("yearsSpentInYouthTeams", info.yearsSpentInYouthTeams.toString())
    info.youthTeamOptions && formData.append("youthTeamOptions", handleStringParameter(info.youthTeamOptions))
    info.hasPlayedInProfessionalTeam &&
      formData.append("hasPlayedInProfessionalTeam", handleBooleanParameter(info.hasPlayedInProfessionalTeam))
    info.professionalTeam && formData.append("professionalTeam", handleStringParameter(info.professionalTeam))
    info.yearsSpentInProfessionalTeams &&
      formData.append("yearsSpentInProfessionalTeams", info.yearsSpentInProfessionalTeams.toString())
    info.videoLink && formData.append("videoLink", info.videoLink)
    info.hasMedicalCertificate &&
      formData.append("hasMedicalCertificate", handleBooleanParameter(info.hasMedicalCertificate))
    info.shortSize && formData.append("shortSize", handleStringParameter(info.shortSize))
    info.canTrainingBefore && formData.append("canTrainingBefore", handleBooleanParameter(info.canTrainingBefore))
    info.hasAccessToCountry && formData.append("hasAccessToCountry", handleBooleanParameter(info.hasAccessToCountry))
    info.isAbleToMove && formData.append("isAbleToMove", handleBooleanParameter(info.isAbleToMove))
    info.shoesSize && formData.append("shoesSize", info.shoesSize.toString())
    if (info.playerImage?.length) {
      formData.append("playerImage", info.playerImage[0].originFileObj as Blob)
    }
    if (info.passportFiles?.length) {
      info.passportFiles.forEach((file, index) => {
        formData.append("passport" + (index + 1), file.originFileObj as Blob)
      })
    }
    if (info.curpFiles?.length) {
      info.curpFiles.forEach((file, index) => {
        formData.append("curp-id" + (index + 1), file.originFileObj as Blob)
      })
    }
    if (info.nationalIdFiles?.length) {
      info.nationalIdFiles.forEach((file, index) => {
        formData.append("id-card" + (index + 1), file.originFileObj as Blob)
      })
    }
    if (info.medicalCertificateFiles?.length) {
      info.medicalCertificateFiles.forEach((file, index) => {
        formData.append("medical-certificate" + (index + 1), file.originFileObj as Blob)
      })
    }
    formData.append("token", token)
    const response = await api.request({
      method: "POST",
      url: API_PATH + "/api/v1/draftPlayers",
      data: formData,
    })
    return response.data
  },
}
