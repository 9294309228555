import React from "react"
import { useTranslation } from "react-i18next"
import "./style.scss"
import { Language } from "types/generic"
import { DatePicker } from "antd"
import { Dayjs } from "dayjs"
import { ConfigProvider } from "antd"
import { localeSelection } from "utility/localeSelection"
import { Locale } from "antd/es/locale"

type DatePickerProps = {
  placeholder?: string
  value?: Dayjs
  picker?: "year" | "month"
  allowClear?: boolean
  bigLabel?: boolean
  readOnly?: boolean
  dateFormat?: string
  alertMessage?: string
  alertMessageColor?: string
  onChange?: (e: Dayjs | null) => void
}

export default function DatePickerKama(props: DatePickerProps) {
  const {
    placeholder,
    dateFormat,
    value,
    allowClear,
    bigLabel,
    readOnly,
    picker,
    alertMessage,
    alertMessageColor,
    onChange,
  } = props

  const language = "it" as Language // TODO: get language from browser;

  const [locale, setLocale] = React.useState<Locale>(localeSelection(language))

  const { t } = useTranslation()

  return (
    <ConfigProvider locale={locale}>
      <div className="datePickerGlobalKamaDiv">
        <div className={"datePickerKamaDiv" + (bigLabel ? " datePickerBigLabelKamaDiv" : "")}>
          <DatePicker
            size="large"
            value={value}
            allowClear={allowClear === undefined ? true : allowClear}
            className={"datePickerKamaElement" + (readOnly ? " datePickerKamaElementReadOnly" : "")}
            placeholder={placeholder ? t(placeholder) : undefined}
            disabled={readOnly || undefined}
            picker={picker}
            suffixIcon={<img src={require("assets/images/picker/date.png")} className={"datePickerKamaElementIcon"} />}
            onSelect={onChange}
            onChange={onChange}
            format={dateFormat || locale.DatePicker?.lang?.dateFormat}
          />
        </div>
        {alertMessage ? (
          <div className="datePickerElementWarningDiv" style={{ color: alertMessageColor || undefined }}>
            <span>{t(alertMessage)}</span>
          </div>
        ) : null}
      </div>
    </ConfigProvider>
  )
}
