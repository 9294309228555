import { RegionDTO } from "types/generic"

export const REGION: RegionDTO[] = [
  {
    id: 236,
    name: "Aguascalientes",
    shortName: null,
    countryId: 57,
  },
  {
    id: 237,
    name: "Baja California",
    shortName: null,
    countryId: 57,
  },
  {
    id: 238,
    name: "Baja California Sur",
    shortName: null,
    countryId: 57,
  },
  {
    id: 239,
    name: "Campeche",
    shortName: null,
    countryId: 57,
  },
  {
    id: 240,
    name: "Chiapas",
    shortName: null,
    countryId: 57,
  },
  {
    id: 241,
    name: "Chihuahua",
    shortName: null,
    countryId: 57,
  },
  {
    id: 242,
    name: "Ciudad de México",
    shortName: null,
    countryId: 57,
  },
  {
    id: 243,
    name: "Coahuila",
    shortName: null,
    countryId: 57,
  },
  {
    id: 244,
    name: "Colima",
    shortName: null,
    countryId: 57,
  },
  {
    id: 245,
    name: "Durango",
    shortName: null,
    countryId: 57,
  },
  {
    id: 246,
    name: "Guanajuato",
    shortName: null,
    countryId: 57,
  },
  {
    id: 247,
    name: "Guerrero",
    shortName: null,
    countryId: 57,
  },
  {
    id: 248,
    name: "Hidalgo",
    shortName: null,
    countryId: 57,
  },
  {
    id: 249,
    name: "Jalisco",
    shortName: null,
    countryId: 57,
  },
  {
    id: 250,
    name: "Estado de México",
    shortName: null,
    countryId: 57,
  },
  {
    id: 251,
    name: "Michoacán",
    shortName: null,
    countryId: 57,
  },
  {
    id: 252,
    name: "Morelos",
    shortName: null,
    countryId: 57,
  },
  {
    id: 253,
    name: "Nayarit",
    shortName: null,
    countryId: 57,
  },
  {
    id: 254,
    name: "Nuevo León",
    shortName: null,
    countryId: 57,
  },
  {
    id: 255,
    name: "Oaxaca",
    shortName: null,
    countryId: 57,
  },
  {
    id: 256,
    name: "Puebla",
    shortName: null,
    countryId: 57,
  },
  {
    id: 257,
    name: "Querétaro",
    shortName: null,
    countryId: 57,
  },
  {
    id: 258,
    name: "Quintana Roo",
    shortName: null,
    countryId: 57,
  },
  {
    id: 259,
    name: "San Luis Potosí",
    shortName: null,
    countryId: 57,
  },
  {
    id: 260,
    name: "Sinaloa",
    shortName: null,
    countryId: 57,
  },
  {
    id: 261,
    name: "Sonora",
    shortName: null,
    countryId: 57,
  },
  {
    id: 262,
    name: "Tabasco",
    shortName: null,
    countryId: 57,
  },
  {
    id: 263,
    name: "Tamaulipas",
    shortName: null,
    countryId: 57,
  },
  {
    id: 264,
    name: "Tlaxcala",
    shortName: null,
    countryId: 57,
  },
  {
    id: 265,
    name: "Veracruz",
    shortName: null,
    countryId: 57,
  },
  {
    id: 266,
    name: "Yucatán",
    shortName: null,
    countryId: 57,
  },
  {
    id: 267,
    name: "Zacatecas",
    shortName: null,
    countryId: 57,
  },
]
