import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { CheckOutlined } from "@ant-design/icons"

import "./style.scss"
import { AllPageColors, AllPageType } from "types/allPage"
import { getColorsByType } from "components/AllPage/utility"

type SuccessRegistrationCardProps = {
  type: AllPageType
}

export default function SuccessRegistrationCard(props: SuccessRegistrationCardProps) {
  const { type } = props
  const { t } = useTranslation()

  const [colors, setColors] = useState<AllPageColors>(getColorsByType(type))
  return (
    <div className="successRegistrationCard">
      <div className="successGlobalDiv">
        <div className="successIconDiv" style={{ background: colors.success }}>
          <CheckOutlined className="successIcon" />
        </div>
        <div className="successTitleDiv" style={{ color: colors.success }}>
          <span>{t("SUCCESS")}</span>
        </div>
        <div className="successTextDiv">
          <span>{t("SUCCESS_DESCRIPTION")}</span>
        </div>
      </div>
    </div>
  )
}
