import React from "react"
import { useTranslation } from "react-i18next"

import RegistrationCard from "components/RegistrationCard"

import { useCountdown } from "../../utility"

import "./style.scss"

const ShowCounter = ({ days, hours, minutes, seconds, isClosed }) => {
  const { t } = useTranslation()
  return (
    <div className="show-counter-container">
      <div className="show-counter">
        <div className="counter-element">
          <span className="counter-value">{days < 10 ? "0" + days : days}</span>
          <span>{t("DAYS")}</span>
        </div>
        <div className="counter-value">:</div>
        <div className="counter-element">
          <span className="counter-value">{hours < 10 ? "0" + hours : hours}</span>
          <span>{t("HOURS")}</span>
        </div>
        <div className="counter-value">:</div>
        <div className="counter-element">
          <span className="counter-value">{minutes < 10 ? "0" + minutes : minutes}</span>
          <span>{t("MINS")}</span>
        </div>
        <div className="counter-value">:</div>
        <div className="counter-element">
          <span className="counter-value">{seconds < 10 ? "0" + seconds : seconds}</span>
          <span>{t("SECONDS")}</span>
        </div>
      </div>
      <div className="counter-title">{t(isClosed ? "DRAFT_CLOSED" : "COMING_SOON")}</div>
    </div>
  )
}

const CountdownTimer = ({ targetDate, type, isClosed }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  if (days + hours + minutes + seconds <= 0) {
    if (isClosed) {
      return <ShowCounter days={0} hours={0} minutes={0} seconds={0} isClosed={isClosed} />
    }
    return <RegistrationCard type={type} />
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} isClosed={false} />
  }
}

export default CountdownTimer
