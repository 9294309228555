import React, { MouseEventHandler } from "react"
import "./style.scss"
import { useTranslation } from "react-i18next"
import { LeftOutlined } from "@ant-design/icons"
import KamaIcon from "components/Icon"

type ModalHeaderProps = {
  title?: string | JSX.Element
  onClose?: MouseEventHandler
  onGoBack?: MouseEventHandler | null
}

export default function ModalHeader(props: ModalHeaderProps) {
  const { title, onClose, onGoBack } = props
  const { t } = useTranslation()
  return (
    <div className="modalHeaderDiv">
      <div className="modalHeaderTitleDiv">
        {onGoBack && (
          <div className="modalHeaderTitleGoBackDiv" onClick={onGoBack}>
            <LeftOutlined />
          </div>
        )}
        {typeof title === "string" ? <span>{t(title)}</span> : null}
        {typeof title !== "string" ? title : null}
      </div>
      <div
        className="modalHeaderCloseDiv"
        onClick={(e) => {
          if (onClose) {
            onClose(e)
          }
        }}
      >
        {onClose && <KamaIcon icon="close" size="small" />}
      </div>
    </div>
  )
}
