import { AllPageType, AllPageColors, AllPageButtonType } from "types/allPage"
import { RegistrationInfo, RegistrationMandatoryFunction } from "types/registration"
import {
  INFO_STEPS,
  MANDATORY_STEPS,
  KL_AMERICAS_INFO_STEPS,
  KL_AMERICAS_MANDATORY_STEPS,
  QL_AMERICAS_INFO_STEPS,
  QL_AMERICAS_MANDATORY_STEPS,
} from "utility/constants"

export const getColorsByType = (type: AllPageType): AllPageColors => {
  let colors: AllPageColors = {
    background: `var(--kama-color-dark-grey)`,
    border: "var(--kama-color-primary)",
    title: "var(--kama-color-primary)",
    description: "var(--kama-color-white)",
    label: "var(--kama-color-white)",
    mandatoryLabel: "var(--kama-color-primary)",
    footer: "var(--kama-color-primary)",
    success: "var(--kama-color-primary)",
  }
  switch (type) {
    case "kings-league-america":
    case "queens-league-america":
      colors = {
        background: `var(--kingsleague-color-light-primary)`,
        border: "var(--kingsleague-color-primary)",
        title: "var(--kingsleague-color-primary)",
        description: "var(--kingsleague-color-black)",
        label: "var(--kingsleague-color-black)",
        mandatoryLabel: "var(--kingsleague-color-primary)",
        //footer: "var(--kingsleague-color-grey)",
        footer: "var(--kingsleague-color-black)",
        success: "var(--kama-color-primary)",
      }
      break
    default:
      break
  }
  return colors
}

export const getButtonByType = (type: AllPageType): AllPageButtonType => {
  let buttonType: AllPageButtonType = "primary"
  switch (type) {
    case "kings-league-america":
      buttonType = "primary-kl"
      break
    case "queens-league-america":
      buttonType = "primary"
      break
    default:
      break
  }
  return buttonType
}

export const getImagesByType = (type: AllPageType): string[] => {
  let images: string[] = [require("assets/images/allPage/kama.png")]
  switch (type) {
    case "kings-league-america":
    case "queens-league-america":
      images = [require("assets/images/allPage/kingsleague.png")]
      break
    default:
      break
  }
  return images
}

export const getInfosByType = (
  type: AllPageType,
  page: number,
  values: RegistrationInfo,
): { info: (keyof RegistrationInfo)[]; mandatory: (keyof RegistrationInfo)[] } => {
  let info: (keyof RegistrationInfo)[] = []
  let mandatory: RegistrationMandatoryFunction | undefined = undefined
  switch (type) {
    case "kings-league-america":
      info = KL_AMERICAS_INFO_STEPS[page]
      mandatory = KL_AMERICAS_MANDATORY_STEPS[page]
      break
    case "queens-league-america":
      info = QL_AMERICAS_INFO_STEPS[page]
      mandatory = QL_AMERICAS_MANDATORY_STEPS[page]
      break
    case "kama":
      info = INFO_STEPS[page]
      mandatory = MANDATORY_STEPS[page]
      break
    default:
      break
  }
  return { info, mandatory: mandatory ? mandatory(values) : [] }
}
