import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { LoadingOutlined } from "@ant-design/icons"

import "./style.scss"
import { AllPageColors, AllPageType } from "types/allPage"
import { getColorsByType } from "components/AllPage/utility"

type LoadingProps = {
  open?: boolean
  description?: string
  secondDescription?: string
  type?: AllPageType
}

export default function Loading(props: LoadingProps) {
  const { open, description, secondDescription, type } = props
  const { t } = useTranslation()

  const [colors, setColors] = useState<AllPageColors>(getColorsByType(type || "kama"))

  if (!open) {
    return null
  }

  return (
    <div className="kamaLoading">
      <div className="kamaLoadingDiv">
        <LoadingOutlined className="kamaLoadingIcon" style={{ color: colors.title }} />
        {description ? (
          <div className="kamaLoadingDescriptionDiv">
            <span>{t(description)}</span>
            {secondDescription ? (
              <div className="kamaLoadingSecondDescriptionDiv">
                <span>{t(secondDescription)}</span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}
