import React, { Component } from "react"
import { message, Upload } from "antd"
import { MAX_PDF_SIZE } from "utility/image"
import { uploadButton } from "../Image"
import "./style.scss"

class FileUploader extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onRemove = this.onRemove.bind(this)
    this.beforeUpload = this.beforeUpload.bind(this)
  }

  onChange = ({ fileList }) => {
    const { uploadFileValues, maxFileSize, maxLength } = this.props
    const fileSizeLimiter = maxFileSize || MAX_PDF_SIZE
    const newFileList = []
    if (fileList?.length) {
      fileList.forEach((file) => {
        if (file.size < fileSizeLimiter) {
          newFileList.push(file)
        } else {
          message.warning({ content: <div className="warningDimensionUploadDiv">File too large</div>, duration: 2 })
        }
      })
    }
    if (newFileList.length > maxLength) {
      newFileList.splice(maxLength)
    }
    uploadFileValues(newFileList)
  }

  onRemove = (file) => {
    const { uploadFileValues, fileList } = this.props
    const index = fileList.indexOf(file)
    const newFileList = fileList.slice()
    newFileList.splice(index, 1)
    return uploadFileValues(newFileList, null)
  }

  beforeUpload = (file) => {
    return false
  }

  render() {
    const { fileList, maxLength, uploadName, acceptType, type } = this.props
    return (
      <Upload
        accept={acceptType}
        beforeUpload={this.beforeUpload}
        onRemove={this.onRemove}
        onChange={this.onChange}
        multiple={maxLength > 1}
        fileList={fileList}
      >
        {fileList && fileList.length >= maxLength ? null : uploadButton(uploadName, false, type)}
      </Upload>
    )
  }
}

export default FileUploader
