import React from "react"
import { useTranslation } from "react-i18next"

import { Radio, Space } from "antd"

import "./style.scss"
import { AllPageType } from "types/allPage"
import { useResolution } from "utility"

type RadioButtonProps = {
  className?: string
  value?: string | number
  options?:
    | {
        id: string | number
        name?: string | null
        element?: JSX.Element | null
        disabled?: boolean | null
      }[]
    | null
  disabled?: boolean | null
  type?: AllPageType
  onChange?: (value: number) => void
}

export default function RadioButtonKama(props: RadioButtonProps) {
  const { className, value, options, disabled, type, onChange } = props
  const { t } = useTranslation()

  const [width] = useResolution()
  const isMobile = width <= 768

  let content: JSX.Element[] | JSX.Element | undefined = options?.map((option) => {
    return (
      <Radio.Button
        value={option.id}
        key={option.id}
        className={"radioButtonSingleKamaElement" + (type ? " " + type : "")}
        disabled={option.disabled || undefined}
      >
        {option.name && t(option.name)}
        {option.element ? option.element : null}
      </Radio.Button>
    )
  })

  if (isMobile) {
    content = <Space direction="vertical">{content}</Space>
  }

  return (
    <div className={"radioButtonGlobalKamaDiv" + (className ? " " + className : "")}>
      <div className={"radioButtonKamaDiv"}>
        <Radio.Group
          className={"radioButtonKamaElement"}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value)
            }
          }}
          value={value || value === 0 ? value : undefined}
          disabled={disabled || undefined}
        >
          {content}
        </Radio.Group>
      </div>
    </div>
  )
}
