import React, { MouseEventHandler } from "react"
import "./style.scss"

export type ButtonType =
  | "radius"
  | "primary"
  | "primary-serie-a"
  | "primary-kl"
  | "default"
  | "inverse-primary"
  | "small"
  | "delete"
  | "radius-primary"
  | "order"
  | string
  | null

type ButtonProps = {
  type?: ButtonType | null
  className?: string
  content?: React.ReactNode | null
  onClick?: MouseEventHandler | null
  disabled?: boolean | null
}

export default function Button(props: ButtonProps) {
  const { type, className, content, onClick, disabled } = props
  return (
    <div className="kamaButtonGlobalDiv">
      <div
        className={
          "kamaButtonDiv" +
          (type === "small" ? " kamaButtonSmallDiv" : "") +
          (type === "default" ? " kamaButtonDefaultDiv" : "") +
          (type === "primary" ? " kamaButtonPrimaryDiv" : "") +
          (type === "primary-serie-a" ? " kamaButtonPrimarySerieADiv" : "") +
          (type === "primary-kl" ? " kamaButtonPrimaryKLDiv" : "") +
          (type === "inverse-primary" ? " kamaButtonInversePrimaryDiv" : "") +
          (type === "transparent-primary" ? " kamaButtonTransparentPrimaryDiv" : "") +
          (type === "delete" ? " kamaButtonDeleteDiv" : "") +
          (type === "radius" ? " kamaButtonRadiusDiv" : "") +
          (className ? " " + className : "") +
          (type === "order" ? " kamaButtonUpdateDiv" : "") +
          (disabled ? " kamaButtonDisabledDiv" : "")
        }
        onClick={(e) => {
          if (disabled) {
            return null
          }
          e.preventDefault()
          if (onClick) {
            onClick(e)
          }
        }}
      >
        {content}
      </div>
    </div>
  )
}
