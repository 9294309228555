import React, { useEffect, useState } from "react"
import NavigationElement, { NavigationElementProps } from "./element"
import "./style.scss"
import KamaIcon from "components/Icon"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { AllPageType } from "types/allPage"
import { getButtonByType } from "components/AllPage/utility"
import { Tooltip } from "antd"
import { useResolution } from "utility"
import { Turnstile } from "@marsidev/react-turnstile"
import type { TurnstileInstance } from "@marsidev/react-turnstile"

type NavigationBarProps = {
  steps: NavigationElementProps[]
  children: JSX.Element
  currentStep: number
  type: AllPageType
  disabledNext?: boolean
  confirmButtonText?: string
  missingFields?: string[]
  onClick: (e: React.MouseEvent, step: number) => void
  confirm?: (e: React.MouseEvent, token: string) => void
}

export default function NavigationBar(props: NavigationBarProps) {
  const { steps, children, currentStep, type, disabledNext, confirmButtonText, missingFields, onClick, confirm } = props
  const { t } = useTranslation()
  if (steps?.length === 1) {
    return children
  }

  const [token, setToken] = React.useState<string | null>(null)
  const ref = React.useRef<TurnstileInstance>(null)

  const [width] = useResolution()
  const isMobile = width <= 768

  const getMissingTooltip = (): JSX.Element | undefined => {
    if (!missingFields?.length) {
      return undefined
    }
    return (
      <div>
        <span>{t("MISSING_FIELDS")}:</span>
        {missingFields.map((field) => {
          return <div key={field}>{t(field)}</div>
        })}
      </div>
    )
  }

  return (
    <div className="navigation-bar">
      <div className="navigation-header">
        {currentStep > 1 ? (
          <div
            className="move"
            onClick={(e) => {
              onClick(e, currentStep - 1)
            }}
          >
            <KamaIcon icon={"arrow-left"} />
          </div>
        ) : null}
        <div className="navigation-header-main">
          <div className={"bs-wizard " + type}>
            {steps.map((step) => {
              return (
                <NavigationElement
                  key={step.step}
                  status={step.status}
                  title={step.title}
                  step={step.step}
                  onClick={onClick}
                  totalSteps={steps.length}
                />
              )
            })}
          </div>
          <div className="navigation-current-step">{t(steps.filter((s) => s.step === currentStep)?.[0].title)}</div>
        </div>
        {currentStep < steps.length ? (
          <Tooltip title={getMissingTooltip()}>
            <div
              className={"move" + (disabledNext ? " disabled" : "")}
              onClick={
                !disabledNext
                  ? (e) => {
                      onClick(e, currentStep + 1)
                    }
                  : undefined
              }
            >
              <KamaIcon icon={"arrow-right"} />
            </div>
          </Tooltip>
        ) : null}
      </div>
      <div className="navigation-bar-children-div">
        {children}
        {currentStep === steps.length && confirm ? (
          <div className="turnstile-container">
            <Turnstile
              siteKey="0x4AAAAAAAMdBQYfMqfkTaeC"
              options={{ refreshExpired: "manual" }}
              onSuccess={setToken}
              onExpire={() => {
                ref.current?.reset()
                setToken(null)
              }}
              onError={() => setToken(null)}
            />
          </div>
        ) : null}
      </div>
      <div className="navigation-bar-buttons-div">
        {!isMobile ? (
          <div className="registrationCardNavigationPrevDiv">
            {currentStep > 1 ? (
              <Button
                type={getButtonByType(type)}
                onClick={(e) => {
                  onClick(e, currentStep - 1)
                }}
                content={
                  <div className="navigation-bar-button-content navigation-bar-button-content-prev">
                    <KamaIcon icon={"arrow-left"} />
                    <span>{t("PREVIOUS")} </span>
                  </div>
                }
              />
            ) : null}
          </div>
        ) : null}
        <div className="registrationCardNavigationNextDiv">
          {currentStep < steps.length ? (
            <Tooltip title={getMissingTooltip()}>
              <div>
                <Button
                  type={getButtonByType(type)}
                  onClick={(e) => {
                    onClick(e, currentStep + 1)
                  }}
                  disabled={disabledNext}
                  content={
                    <div className="navigation-bar-button-content navigation-bar-button-content-next">
                      <span>{t("NEXT")} </span>
                      <KamaIcon icon={"arrow-right"} />
                    </div>
                  }
                />
              </div>
            </Tooltip>
          ) : null}
          {currentStep === steps.length && confirm ? (
            <Tooltip title={getMissingTooltip()}>
              <Button
                type={getButtonByType(type)}
                onClick={(e) => token && confirm(e, token)}
                disabled={disabledNext || !token?.length}
                content={
                  <Tooltip title={getMissingTooltip()} getPopupContainer={(triggerNode) => triggerNode.parentElement!}>
                    <div className="navigation-bar-confirm-button">
                      <span>{t(confirmButtonText || "SEND_DATA")} </span>
                    </div>
                  </Tooltip>
                }
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  )
}
